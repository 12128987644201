import React from 'react'

const Interfaces = () => {
  return (
    <>
      <section id="interfaces" className="screenshots-section pb-100 pt-100 gray-light-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9 col-lg-8">
              <div className="section-heading text-center mb-5">
                <h2>App Interfaces</h2>
                {
                  /* <p>Proactively impact value-added channels via backend leadership skills. Efficiently
                         revolutionize worldwide networks whereas strategic catalysts for change. </p> */
                }
              </div>
            </div>
          </div>
          {
            /*start app screen carousel*/
          }
          <div className="screenshot-wrap">
            <div className="screenshot-frame" />
            <div className="screen-carousel owl-carousel owl-theme dot-indicator">
              <img loading="lazy" src="assets/img/mobile_ui/welcome.png" className="img-fluid" alt="screenshots" />
              <img loading="lazy" src="assets/img/mobile_ui/signin.png" className="img-fluid" alt="screenshots" />
              <img loading="lazy" src="assets/img/mobile_ui/sign_up.png" className="img-fluid" alt="screenshots" />
            </div>
          </div>
          {
            /*end app screen carousel*/
          }
        </div>
      </section>
    </>
  )
}

export default Interfaces