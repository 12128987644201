import React from 'react'

const Services = () => {
    return (
        <>
            <section className="promo-section ptb-80" id="services">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-8">
                            <div className="section-heading text-center">
                                <h2>Shakib 75 Major Services</h2>
                                <p>Objectively deliver professional value with diverse web-readiness. Collaboratively
                                    transition wireless customer service without goal-oriented catalysts for change.
                                    Collaboratively.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-md-center justify-content-sm-center">
                        <div className="col-md-6 col-lg-4">
                            <div className="card border-0 single-promo-card single-promo-hover text-center p-2 mt-4">
                                <a href={"/card"} className="page-scroll">

                                    <div className="card-body">
                                        <div className="pb-2">
                                            <span className="fas fa-credit-card icon-size-lg color-primary"/>
                                        </div>
                                        <div className="pt-2 pb-3">
                                            <h5>Health Card</h5>
                                            <p className="mb-0 text-justify">Shakib 75 Healthcare has launched their
                                                “Healthcard” service in Bangladesh for their corporate clients.</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="card border-0 single-promo-card single-promo-hover text-center p-2 mt-4">
                                <a href="#covid_care" className="page-scroll">
                                    <div className="card-body">
                                        <div className="pb-2">
                                            <span className="fas fa-viruses icon-size-lg color-primary"/>
                                        </div>
                                        <div className="pt-2 pb-3">
                                            <h5>Covid Care Package</h5>
                                            <p className="mb-0 text-justify">We have various packages which provide
                                                special care to Covid patients including telemedicine services and covid
                                                test.</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="card border-0 single-promo-card single-promo-hover text-center p-2 mt-4">
                                <a href="#features" className="page-scroll">
                                    <div className="card-body">
                                        <div className="pb-2">
                                            <span className="fas fa-clinic-medical icon-size-lg color-primary"/>
                                        </div>
                                        <div className="pt-2 pb-3">
                                            <h5>TeleMedicine</h5>
                                            <p className="mb-0 text-justify">We provide all employees and their family
                                                members of our partners to be treated 24/7 through telemedicine.</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Services