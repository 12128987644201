import React from 'react'

const WorkProcess = () => {
    return (
        <>
            <section id="process" className="work-process-section position-relative  ptb-100 ">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-9 col-lg-8">
                            <div className="section-heading text-center mb-5">
                                <h2>Work Progress</h2>
                                <p>
                                    You don&apos;t need to wait physically hours in hospital to see a doctor.
                                    You can join the online queue immediately, while you can continue doing other tasks at
                                    home or office.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-md-center justify-content-sm-center">
                        <div className="col-sm-12 col-md-7 col-lg-6 d-sm-block d-md-block d-lg-none pb-80">
                            <div className="img-wrap">
                                <img loading="lazy" src="assets/img/process.png" alt="features" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6">
                            <div className="work-process-wrap">
                                <div className="process-single-item">
                                    <div className="process-icon-item left-shape">
                                        <div className="d-flex align-items-center">
                                            <div className="process-icon mr-4">
                                                {/* <i class="fas fa-project-diagram color-primary"></i> */}
                                                <i className="fas fa-search color-primary" />
                                            </div>
                                            <div className="process-content text-left">
                                                <h5>Search your Doctor!</h5>
                                                <p>Search your doctor by specialty or doctor name. Choose the right doctor
                                                    for you by viewing their profile, rating &amp; experience.</p>
                                            </div>
                                        </div>
                                        <svg x="0px" y="0px" width="312px" height="130px">
                                            <path className="dashed1" fill="none" stroke="rgb(95, 93, 93)" strokeWidth={1} strokeDasharray={1300} strokeDashoffset={0} d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338">
                                            </path>
                                            <path className="dashed2" fill="none" stroke="#ffffff" strokeWidth={2} strokeDasharray={6} strokeDashoffset={1300} d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338 ">
                                            </path>
                                        </svg>
                                    </div>
                                </div>
                                <div className="process-single-item">
                                    <div className="process-icon-item right-shape">
                                        <div className="d-flex align-items-center">
                                            <div className="process-icon ml-4">
                                                <i className="fas fa-video color-primary" />
                                            </div>
                                            <div className="process-content text-right">
                                                <h5>Consult live on VideoCall</h5>
                                                <p>Once you pay the required doctor fee, you will be joined to the queue.
                                                    Doctor will make a secured video call to do the consultation.</p>
                                            </div>
                                        </div>
                                        <svg x="0px" y="0px" width="312px" height="130px">
                                            <path className="dashed1" fill="none" stroke="rgb(95, 93, 93)" strokeWidth={1} strokeDasharray={1300} strokeDashoffset={0} d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996">
                                            </path>
                                            <path className="dashed2" fill="none" stroke="#ffffff" strokeWidth={2} strokeDasharray={6} strokeDashoffset={1300} d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996">
                                            </path>
                                        </svg>
                                    </div>
                                </div>
                                <div className="process-single-item">
                                    <div className="process-icon-item left-shape mb-0">
                                        <div className="d-flex align-items-center">
                                            <div className="process-icon mr-4">
                                                <i className="far fa-file-alt color-primary" />
                                            </div>
                                            <div className="process-content text-left">
                                                <h5>Instant Prescription</h5>
                                                <p>Once the video consultation is complete, the doctor will upload the
                                                    prescription. You can download the prescription immediately or later.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 col-lg-6 d-none d-lg-block">
                            <div className="img-wrap">
                                <img loading="lazy" src="assets/img/process.png" alt="features" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WorkProcess