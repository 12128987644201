import React, { useEffect, useState } from 'react'
import config from '../utils/config'

const Footer = () => {
  const [url, setUrl] = useState(config.websiteURL)
  useEffect(() => {

      if (typeof window !== "undefined") {
          setUrl(window.location.origin)
      }
  }, [])
  return (
    <>
      <footer className="footer-1 gradient-bg ptb-60 footer-with-newsletter">

        <div className="container" style={{
          display: 'none'
        }}>
          <div className="row newsletter-wrap primary-bg rounded shadow-lg p-5">
            <div className="col-md-6 col-lg-7 mb-4 mb-md-0 mb-sm-4 mb-lg-0">
              <div className="newsletter-content text-white">
                <h3 className="mb-0 text-white">Subscribe our Newsletter</h3>
                <p className="mb-0">We’re a team of non-cynics who truly care for our work.</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-5">
              <form className="newsletter-form position-relative">
                <input type="text" className="input-newsletter form-control" placeholder="Enter your email" name="email" required autoComplete="off" />
                <button type="submit" className="disabled"><i className="fas fa-paper-plane" /></button>
              </form>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-4 mb-4 mb-md-4 mb-sm-4 mb-lg-0">
              <a href='!#' className="navbar-brand mb-2">
                <img loading="lazy" src="assets/img/logo_75.png" alt="logo" className="img-fluid logo_footer" />
              </a>
              <br />
              <p>The Most Complete Health Solution in your Hand.</p>
              <div className="list-inline social-list-default background-color social-hover-2 mt-2">
                <li className="list-inline-item"><a href='!#' className="twitter" type="button" data-toggle="modal" data-target="#appsModal"><i className="fab fa-twitter" /></a></li>
                <li className="list-inline-item"><a className="facebook" href="https://www.facebook.com/Shakib75Healthcare/" target="_blank" rel="noreferrer"><i className="fab fa-facebook" /></a></li>
                <li className="list-inline-item"><a className="youtube" href='!#' type="button" data-toggle="modal" data-target="#appsModal"><i className="fab fa-youtube" /></a></li>
                <li className="list-inline-item"><a className="linkedin" href='!#' type="button" data-toggle="modal" data-target="#appsModal"><i className="fab fa-linkedin-in" /></a>
                </li>
              </div>
            </div>
            <div className="col-md-12 col-lg-8">
              <div className="row mt-0">
                <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                  <h6 className="text-uppercase">Quick Link</h6>
                  <ul>
                    <li>
                      <a href="#about">About</a>
                    </li>
                    <li>
                      <a href={url + "/privacy-policy"}>Privacy Policy</a>
                    </li>
                    <li>
                      <a href="#doctors">Doctors</a>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                  <h6 className="text-uppercase">Quick Link</h6>
                  <ul>
                    <li>
                      <a href="#faq">FAQ</a>
                    </li>
                    <li>
                      <a href="#interfaces">Interfaces</a>
                    </li>
                    <li>
                      <a href="#contact-us">Contact</a>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                  <h6 className="text-uppercase">Dowload Now</h6>
                  <ul>
                    <li className="list-inline-item my-2 footer_dli">
                      <a href="https://play.google.com/store/apps/details?id=com.creativeitsoft.shakib75" target="_blank" rel="noreferrer" className="d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded footer_download">
                        <span className="fab fa-google-play icon-size-sm mr-3" />
                        <div className="download-text text-left">
                          <small>Download form</small>
                          <h5 className="mb-0">Google Play</h5>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer