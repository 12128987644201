import React from 'react'

const Features = () => {
  return (
    <>
      <div id="features" className="feature-section">
        <div className="container pb-40">

          <div className="row align-items-center justify-content-md-center">
            <div className="col-lg-8 col-md-12">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                    <span className="ti-face-smile icon-size-md color-secondary mr-4" />
                    <div className="icon-text">
                      <h5 className="mb-2">Telemedicine</h5>
                      <p>We provide all employees &amp; their family members of our partners to be treated
                        24/7 through telemedicine. Besides, 4 dedicated call center agents will
                        facilitate the service. </p>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                    <span className="ti-vector icon-size-md color-secondary mr-4" />
                    <div className="icon-text">
                      <h5 className="mb-2">Blood Ministration</h5>
                      <p>We serve people in blood donation using digital blood donation ecosystem in
                        association with Facebook and Bloodman. We also provide platelet donar for
                        dengue patients. Blood insurance service is also available here by which
                        people can get the help of seeking blood in their crucial situation. </p>
                    </div>
                  </div>
                </div>
                {
                  /* <div className="col-12">
                   <div className="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                     <span className="ti-headphone-alt icon-size-md color-secondary mr-4" />
                     <div className="icon-text">
                       <h5 className="mb-2">Covid -19 Care</h5>
                       <p>We have various packages which provide special care to Covid patients. Here
                         they can have Covid test where the samples will be taken from patient’s
                         home, regular doctor consultancy via audio and video calls and emergency
                         medical kit which is very crucial for the covid patient. We also arrange
                         plasma donar for critically affected covid patient. </p>
                     </div>
                   </div>
                  </div> */
                }
              </div>
            </div>
            <div className="col-lg-4 col-md-5 d-none d-sm-none d-md-none d-lg-block">
              <div className="position-relative pb-md-5 py-lg-0">
                <img loading="lazy" alt="Shakib75" src="assets/img/app-mobile-image.png" className="img-center img-fluid float-right" />
              </div>
            </div>
            {
              /* <div className="col-lg-4 col-md-5 d-none d-sm-none d-md-block d-lg-block mt-5">
               <div className="position-relative pb-md-5 py-lg-0">
                 <img loading="lazy" alt="Image placeholder" src="assets/img/app-mobile-image-2.png" className="img-center img-fluid float-left" />
               </div>
              </div>
              <div className="col-lg-8 col-md-12 mt-5">
               <div className="row">
                 <div className="col-12">
                   <div className="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                     <span className="ti-layout-media-right icon-size-md color-secondary mr-4" />
                     <div className="icon-text">
                       <h5 className="mb-2">Diagnostic Centers at Home</h5>
                       <p>In reality where people are struggling to manage Covid-19 Tests on time, here
                         SHAKIB 75 will arrange a faster &amp; at home test facility on a priority basis
                         in collaboration with a couple of Govt. Approved Labs. Lab will collect
                         patient’s sample from their home and test result will be provided within 72
                         hours. Total cost for Coved 19 test will be 4500 tk per in Dr. Lal Pathlabs,
                         Prava Health, CSBF Health Center, CRL Diagnostics, DNA Solutions etc. </p>
                     </div>
                   </div>
                 </div>
                 <div className="col-12">
                   <div className="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                     <span className="ti-layout-cta-right icon-size-md color-secondary mr-4" />
                     <div className="icon-text">
                       <h5 className="mb-2">Shakib 75 Healthcard </h5>
                       <p>SHAKIB 75 brings a smart solution for people to get diagnostic discounts
                         showing the health card of SHAKIB 75 Healthcare Service Limited. To reduce
                         the excessive financial burden of diagnostic tests, SHAKIB 75 brings a smart
                         solution for people to get diagnostic discounts in selective hospitals and
                         diagnostic centers by showing the health card of SHAKIB 75 Healthcare
                         Service Limited. With an affordable price of BDT 299, SHAKIB 75 Healthcard
                         can be used for 1 year and after that a renewal will be needed. </p>
                     </div>
                   </div>
                 </div>
               </div>
              </div> */
            }
            {
              /*feature new style end*/
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default Features