import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { apiURL } from '../utils/config'

const Doctors = () => {
  const [doctors, setDoctors] = useState([])
  const [number, setNumber] = useState(5);
  // const [loading, setLoading] = useState(false);
  const getDoctor = (() => {
    axios.get(apiURL + 'webdoctorlist')
      .then(function (response) {
        // handle success
        console.log("response");
        console.log(response.data.data);
        setDoctors(response.data.data)
        // console.log(doctors.length);
      })
      .catch(function (error) {
        // handle error
        console.log(error);

      })
  })
  useEffect(() => {
    getDoctor()
    // console.log(doctors.length);
  }, [])

  const defaultDoctor = [


    {
      id: '3',
      name: 'Dr. Shanzida Zahan',
      position: 'Medical Services Co-Ordinator',
      image: '/assets/img/team/3.png',
      area: 'Shakib75 Healthcare'

    },
    {
      id: '1',
      name: 'Dr. Monzur Hussain Chowdhury',
      position: 'Medical Consultant',
      image: '/assets/img/team/1.png',
      area: 'Shakib75 Healthcare'
    },
    {
      id: '2',
      name: 'Dr. Sumiya Akther',
      position: 'Medical Consultant',
      image: '/assets/img/team/2.png',
      area: 'Shakib75 Healthcare'

    }
  ]

  return (
    <>
      <section className="team-two-section ptb-100 gray-light-bg" id="doctors">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9 col-lg-8">
              <div className="section-heading text-center">
                <h2>Our Doctors</h2>
                <p>Find Out Specialist Doctor in Bangladesh</p>
              </div>
            </div>
          </div>
          <div className="row">

            {defaultDoctor.map((item) =>
              <div className="col-md-6 col-lg-3" key={item.id}>
                <div className="single-team-wrap bg-white text-center border rounded p-4 mt-4" style={{ height: "95%" }}>
                  <img loading="lazy" src={item.image ? item.image : "/assets/img/avatar.jpg"} alt="Shakib75" width={120} className="img-fluid m-auto rounded-circle" style={{ height: "120px", width: "120px", border: "3px solid #6c757d85" }} />
                  <div className="team-content mt-4">
                    <div>
                      <h5 className="mb-0">{item.name}</h5>
                      <span className="text-justify">{item.position}</span>
                    </div>
                    <p className="mt-3">{item.area}</p>

                  </div>
                </div>
              </div>
            )}

            {doctors.slice(0, number).map(({
              degree,
              full_name,
              image,
              specialist,
            }) =>
              <div className="col-md-6 col-lg-3" key={full_name}>
                <div className="single-team-wrap bg-white text-center border rounded p-4 mt-4" style={{ height: "95%" }}>
                  <img loading="lazy" src={image ? image : "/assets/img/avatar.jpg"} alt="Shakib75" width={120} className="img-fluid m-auto rounded-circle" style={{ height: "120px", width: "120px", border: "3px solid #6c757d85" }} />
                  <div className="team-content mt-4">
                    <div>
                      <h5 className="mb-0">{full_name}</h5>
                      <span className="text-justify">{degree}</span>
                    </div>
                    <p className="mt-3">{specialist}</p>

                  </div>
                </div>
              </div>
            )}




          </div>

          {doctors.length > number && (
            <div className='d-flex'>
              <button onClick={() => setNumber(number + 8)} className="btn btn-brand-02 mr-3 btn-rounded mx-auto mt-5" target="_blank" rel="noreferrer">See More Doctors</button>
            </div>
          )}

        </div>
      </section>
    </>
  )
}

export default Doctors