import React from 'react'
import { cardURL } from '../utils/config'

const HealthCard = () => {
  return (
    <>
      <section className="position-relative feature-section ptb-80" id="health_card">
        <div className="container">
          <div className="row align-items-left justify-content-between justify-content-sm-center justify-content-md-left">
            <div className="col-sm-12 col-md-7 col-lg-6 mb-5 mb-md-5 mb-sm-5 mb-lg-0">
              <div className="discount-banner bg-white text-center">
                <div className="download-img">
                  <img loading="lazy" src="assets/img/health_card_1.png" alt="download" className="img-fluid" />
                  <img loading="lazy" src="assets/img/health_card_2.png" alt="download" className="img-fluid" />
                </div>
                {
                  /*                            <a href="#" class="btn btn-brand-01 mt-4 d-block">Subscribe Now</a>*/
                }
              </div>
            </div>
            <div className="col-md-12 col-lg-5">
              <div className="feature-contents">
                <h2>Shakib 75 - Health Card</h2>
                <p>Shakib 75 Healthcare has recently launched their “Healthcard” service in Bangladesh for their corporate clients and general people. Users of this healthcard will be able to get diagnostic discount throughout one whole year which will cost only 475 taka.</p>
                <ul className="dot-circle pt-2">
                  <li>At present, customers can avail up to 35% discount on various health services.</li>
                  <li>By 2022, this discount will be available from 200 hospitals across Bangladesh.</li>
                </ul>
                <div className="action-btns mt-4">
                  <a href={cardURL} className="btn btn-brand-02 mr-3" target="_blank" rel="noreferrer">Take Your Card</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default HealthCard