// import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import config from '../utils/config'
// import { useEffect, useState} from 'react/cjs/react.production.min'
// import { portalURL, websiteURL } from '../utils/config'

const NavBar = () => {
    const [url, setUrl] = useState(config.websiteURL)
    useEffect(() => {

        if (typeof window !== "undefined") {
            // console.log(window.location);
            setUrl(window.location.origin)
        }
    }, [])
    return (
        <>
            <header className="header">
                <nav className="navbar navbar-expand-lg fixed-top bg-transparent">
                    <div className="container">
                        {/* <Link href="/"> */}
                        <a className="navbar-brand" href={url} >
                            <img loading="lazy" src="assets/img/logo_75.png" alt="logo" className="img-fluid logo" />
                        </a>
                        {/* </Link> */}
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">

                            <svg xmlns="http://www.w3.org/2000/svg" width="36px" height="36px" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16m-7 6h7" />
                            </svg>
                        </button>
                        <div className="collapse navbar-collapse h-auto" id="navbarSupportedContent">
                            <ul className="navbar-nav ml-auto menu">
                                <li><a href={url}>Home</a></li>
                                {/* <li><a href="#home" className="page-scroll"> Home</a></li> */}
                                <li><a href="#services" className="page-scroll">Services</a></li>
                                {/* <li><Link href="/card"><a>Health Card</a></Link></li> */}
                                <li><a href={url + "/card"}>Health Card</a></li>
                                <li><a href="#covid_care" className="page-scroll">Covid Care</a></li>
                                <li><a href="https://www.bloodman.org" target="_blank" rel="noreferrer">Blood Donation</a></li>
                                <li><a href="#doctors" className="page-scroll">Our Doctor</a></li>
                                <li> <a href={config.portalURL} className="btn btn-brand-03 btn-rounded mr-3 py-1 mt-3 ml-2" target="_blank" rel="noreferrer">Sign In<i className="fas fa-user pl-2" /></a></li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}

export default NavBar