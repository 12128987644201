import React from 'react'
import { cardURL } from '../utils/config'
// import ReactPlayer from 'react-player'

const Header = () => {
  return (
    <>
      <section className="position-relative ptb-100 bg-gradient-primary min-vh-100 flex-column d-flex justify-content-center" id="home">
        <video className="fit-cover w-100 h-100 position-absolute z--1" src="assets/video.mp4" autoPlay={true} type="video/mp4" muted loop={true} />
        {/* <ReactPlayer url="/assets/video.mp4" playing={true}  volume={0.8} loop={true} muted className="fit-cover w-100 h-100 position-absolute z--1" /> */}
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6 col-lg-6">
              <div className="hero-content-left text-white py-5">
                <h1 className="text-white">Ask the Doctor anytime &amp; from Anywhere</h1>
                <p className="lead">Get the care you need 24/7-online Our spealist doctors will always be ready to help you.</p>
                <div className="action-btns mt-4">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <a href="https://play.google.com/store/apps/details?id=com.creativeitsoft.shakib75" target="_blank" rel="noreferrer" className="d-flex align-items-center app-download-btn btn btn-white btn-rounded apps-color-txt">
                        <span className="fab fa-google-play icon-size-sm mr-3" />
                        <div className="download-text text-lef">
                          <small>Download form</small>
                          <h5 className="mb-0">Google Play</h5>
                        </div>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href={cardURL} target="_blank" rel="noreferrer" className="d-flex align-items-center app-download-btn btn btn-white btn-rounded apps-color-txt">
                        <span className="fa fa-plus icon-size-sm mr-3" />
                        <div className="download-text text-lef">
                          <small>Get your</small>
                          <h5 className="mb-0">Health Card</h5>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-5">
              <div className="hero-animation-img pt-lg-4">
                <img loading="lazy" className="img-fluid glide" src="assets/img/shakib_mockup.png" alt="shakib75" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Header