import React from 'react'
import CovidCare from '../components/CovidCare'
import Doctors from '../components/Doctors'
import Faq from '../components/Faq'
import { cardURL } from '../utils/config'

const currentOffer = [
    {
        id: 1,
        image: '/assets/img/covidcard/1.png'
    },
    {
        id: 2,
        image: '/assets/img/covidcard/2.png'
    },
    {
        id: 3,
        image: '/assets/img/covidcard/3.png'
    },
    {
        id: 4,
        image: '/assets/img/covidcard/4.png'
    },
    {
        id: 5,
        image: '/assets/img/covidcard/5.png'
    },
    {
        id: 6,
        image: '/assets/img/covidcard/6.png'
    },
    {
        id: 7,
        image: '/assets/img/covidcard/7.png'
    },
    {
        id: 8,
        image: '/assets/img/covidcard/8.png'
    },
    {
        id: 9,
        image: '/assets/img/covidcard/9.png'
    }
]

const Card = () => {
    return (
        <>

            <section className="position-relative bg-image pt-100" image-overlay={10}>
                {/*        <video class="fit-cover w-100 h-100 position-absolute z&#45;&#45;1" src="assets/video.mp4" autoplay="true" type="video/mp4" muted="" loop="true"></video>*/}
                <div className="container">
                    <div className="row align-items-center justify-content-lg-between justify-content-md-center justify-content-sm-center">
                        <div className="col-md-12 col-lg-6">
                            <div className="section-heading text-white py-5">
                                <h1 className="text-white font-weight-bold">সাকিব ৭৫ হেলথ কার্ড দেখলেই , বিভিন্ন  স্বাস্থ্য সেবায়  পাচ্ছেন <span style={{ color: '' }} className="anim">৩৫%</span> পর্যন্ত ছাড় ! </h1>
                                <p>বর্তমানে এর গ্রাহকরা ঢাকায় ১১ টি হাসপাতালে বিভিন্ন স্বাস্থ্যসেবায় ৩৫% পর্যন্ত ডিসকাউন্ট পাবেন, 2022 সালের মধ্যে, সারা বাংলাদেশে ২০০টি হাসপাতাল থেকে এই ডিসকাউন্ট পাওয়া যাবে।</p>
                                <div className="action-btns mt-4">
                                    <ul className="list-inline">
                                        <li className="list-inline-item">
                                            <a href={cardURL} className="d-flex align-items-center app-download-btn btn btn-white btn-rounded" target="_blank" rel="noreferrer">
                                                <span className="fa fa-plus icon-size-sm mr-3 color-primary" />
                                                <div className="download-text text-left">
                                                    <h5 className="mb-0">Apply For A Card</h5>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 col-sm-9 col-lg-6">
                            <div className="hero-animation-img">
                                <img loading="lazy" className="img-fluid position-relative img-custom-width" src="/assets/img/cover.png" alt="Shakib75" />
                            </div>
                        </div>
                    </div>
                    {/*end of row*/}
                </div>
                {/*end of container*/}
            </section>

            <section className="position-relative feature-section ptb-100">
                <div className="container">

                    <div className="row justify-content-center">
                        <div className="col-md-12 col-lg-12">
                            <div className="section-heading text-center">
                                <h2>About Shakib 75</h2>
                            </div>
                            {/* <p> SHAKIB 75 Healthcare will ultimately bring the healthcare service at the nearest door of the customers as well as citizens of the country.Shakib 75 Healthcare has recently launched their “Healthcard” service in Bangladesh for their corporate clients and general people. Users of this healthcard will be able to get diagnostic discount throughout one whole year which will cost only 475 taka.</p> */}
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-between justify-content-sm-center justify-content-md-center">
                        <div className="col-sm-5 col-md-6 col-lg-6 mb-5 mb-md-5 mb-sm-5 mb-lg-0">
                            {/* <div className="download-img"> */}
                            <div className="video-promo-content">
                                {/* <img loading="lazy" src="/assets/img/cover.jpeg" alt="" className='about-vimg'/> */}
                                <a href="assets/video.mp4" className="popup-youtube video-play-icon text-center m-auto video_blink">
                                    <span className="fas fa-play" />
                                    {/* <i class="fas-solid fas-play"></i> */}
                                </a>
                            </div>

                            {/* <ReactPlayer url="/assets/video.mp4" playing={true} className="img-fluid" width='100%' height='500px' /> */}
                            {/* </div> */}

                            {/* <ReactPlayer url="/assets/video.mp4" playing={true} className="about_video" /> */}

                        </div>
                        <div className="col-md-12 col-lg-6">
                            {/* <ReactPlayer url="/assets/video.mp4" playing={true} className="img-fluid about_video" muted /> */}
                            <div className="feature-contents">
                                {/* <h2>About</h2> */}
                                <p>SHAKIB 75 Healthcare is a one stop healthcare service with an array of concentrated healthcare products. With a vision to connect underprivileged people of Bangladesh with the digital healthcare service and upgrade their quality of living, SHAKIB 75 Healthcare initiates telemedicine service through advanced technologies and arrangements. Along with its telemedicine coverage, SHAKIB 75 Healthcare provides facility to get diagnostic tests at home, digital blood donor match making services and Covid-19 care facilities including Covid-19 tests and plasma donation activities for critical patients. Being a complete health-tech platform, SHAKIB 75 Healthcare will ultimately bring the healthcare service at the nearest door of the customers as well as citizens of the country.
                                    Shakib 75 Healthcare has recently launched their “Healthcard” service in Bangladesh for their corporate clients and general people. Users of this healthcard will be able to get diagnostic discount throughout one whole year which will cost only 475 taka.</p>



                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="position-relative">
                <div className="container">
                    <div className="row align-items-center">


                        <div className="col-md-12 col-lg-12">

                            <div className="bg-white p-5 rounded text-left shadow mt-lg-0">
                                <div className="icon-text-wrap">
                                    {/* <i className="fab fa-google-play icon-size-md color-primary mb-2" /> */}
                                    <h3>Feature</h3>
                                </div>
                                <div className="p-20px">
                                    <p className="m-0px"> At present, customers can avail up to 35% discount on various health services.By 2022, this discount will be available from 200 hospitals across Bangladesh.</p>

                                </div>
                            </div>

                        </div>


                        <div className="col-md-6 col-lg-6 d-none">
                            <div className="bg-white p-5 rounded text-left shadow mt-lg-0 mt-4 aboutfc">
                                <div className="icon-text-wrap">
                                    {/* <i className="fab fa-google-play icon-size-md color-primary mb-2" /> */}
                                    <h3>Conditions</h3>
                                </div>
                                <div className="p-20px">
                                    <p className="m-0px">The service is currently available for Corporate Employees only. Soon we will open our services to general card holder as well.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="overflow-hidden">
                <section id="services" className="about-us ptb-80  position-relative">
                    <div className="animated-shape-wrap">
                        <div className="animated-shape-item" />
                        <div className="animated-shape-item" />
                        <div className="animated-shape-item" />
                        <div className="animated-shape-item" />
                        <div className="animated-shape-item" />
                    </div>
                    <div className="container">

                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-8">
                                <div className="section-heading text-center">
                                    <h2>Current list of Hospitals and available diagnostic discount</h2>
                                </div>
                            </div>
                        </div>

                        <div className="row align-items-center justify-content-lg-between justify-content-md-center justify-content-sm-center">
                            {
                                currentOffer.map((item, index) =>
                                    <div className="col-sm-12 col-md-7 col-lg-4 d-sm-none d-md-none d-lg-block mt-5" key={index}>
                                        <div className="about-content-right">
                                            <div className="discount-banner-2 bg-white text-center">
                                                <img loading="lazy" src={item.image} alt="sakin75 card" className="img-fluid img-rad" />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </section>
            </div>


            <Faq />
            <CovidCare />
            <Doctors />
        </>
    )
}

export default Card