import React from 'react'
import { portalURL } from '../utils/config'

const CovidCare = () => {
  return (
    <>
      <div className="overflow-hidden">
        <section id="covid_care" className="about-us ptb-80 background-shape-img position-relative">
          <div className="animated-shape-wrap">
            <div className="animated-shape-item" />
            <div className="animated-shape-item" />
            <div className="animated-shape-item" />
            <div className="animated-shape-item" />
            <div className="animated-shape-item" />
          </div>
          <div className="container">
            <div className="row align-items-center justify-content-lg-between justify-content-md-center justify-content-sm-center">
              <div className="col-sm-12 col-md-7 col-lg-6 d-lg-none d-sm-block d-md-block pb-80">
                <div className="about-content-right">
                  <div className="discount-banner-2 bg-white text-center">
                    <img loading="lazy" src="assets/img/covid_care.jpeg" alt="about us" className="img-fluid img-rad"/>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 mb-5 mb-md-6 mb-sm-12 mb-lg-0">
                <div className="about-content-left">
                  <h2>Covid Care Package</h2>
                  <p className="text-justify ">We have various packages which provide special care to Covid patients. Here they can have Covid test where the samples will be taken from patient’s home, regular doctor consultancy via audio and video calls and emergency medical kit which is very crucial for the covid patient. We also arrange plasma donar for critically affected covid patient.</p>
                  <ul className="dot-circle pt-2">
                    <li>Basic Package - Telemedicine services for 14 days (10am to 10pm).</li>
                    <li>Executive Package - Telemedicine services for 14 days, medical goodie bag and one Covid-19 test (sample collection from home).</li>
                    <li>Comprehensive Package - Telemedicine services for 30 days, medical goodie bag, assisting in booking hospital bed and two Covid-19 test (sample collection from home).</li>
                  </ul>
                </div>
                <div className="action-btns mt-4">
                  <a href={portalURL} className="btn btn-brand-02 mr-3" target="_blank" rel="noreferrer">Buy Package</a>
                </div>
              </div>
              <div className="col-sm-12 col-md-7 col-lg-6 d-none d-lg-block">
                <div className="about-content-right">
                  <div className="discount-banner-2 bg-white text-center">
                    <img loading="lazy" src="assets/img/covid_care.jpeg" alt="about us" className="img-fluid img-rad" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default CovidCare