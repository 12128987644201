import React from 'react'
import bkash from '../images/bkash.png'
import rocket from '../images/rocket.png'
import nagad from '../images/nagad.png'
import amex from '../images/amex.png'
import bankasia from '../images/bankasia.png'
import brac_bank from '../images/brac_bank.jpg'
import city_logo from '../images/city_logo.png'
import dbbl from '../images/dbbl.png'
import dmoney from '../images/dmoney.png'
import ebl from '../images/ebl.png'
import master from '../images/master.png'
import MDB from '../images/MDB.jpg'
import ok from '../images/ok.png'
import rainbow from '../images/rainbow.png'
import ucb_bank from '../images/ucb_bank.png'
import upay from '../images/upay.png'
import visa from '../images/visa.png'

const Faq = () => {

  const payWith = [
    {
      id: 1,
      name: 'Bkash',
      image: bkash
    },
    {
      id: 2,
      name: 'Nagad',
      image: nagad
    },
    {
      id: 3,
      name: 'Rocket',
      image: rocket
    },
    {
      id: 4,
      name: 'Amex',
      image: amex
    },
    {
      id: 5,
      name: 'Bank Asia',
      image: bankasia
    },
    {
      id: 6,
      name: 'Brac Bank',
      image: brac_bank
    },
    {
      id: 7,
      name: 'City Bank',
      image: city_logo
    },
    {
      id: 8,
      name: 'Dbbl',
      image: dbbl
    },
    {
      id: 9,
      name: 'DMoney',
      image: dmoney
    },
    {
      id: 10,
      name: 'EBL',
      image: ebl
    },
    {
      id: 11,
      name: 'MDB',
      image: MDB
    },
    {
      id: 12,
      name: 'OK',
      image: ok
    },
    {
      id: 13,
      name: 'Rainbow',
      image: rainbow
    }
    ,
    {
      id: 14,
      name: 'Ucb Bank',
      image: ucb_bank
    }
    ,
    {
      id: 15,
      name: 'UPay',
      image: upay
    }
    ,
    {
      id: 16,
      name: 'Visa',
      image: visa
    }
    ,
    {
      id: 17,
      name: 'Master',
      image: master
    }
  ]

  return (
    <>
      <section id="faq" className="ptb-100 ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9 col-lg-8">
              <div className="section-heading text-center mb-5">
                <h2>Frequently Asked Queries</h2>

              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-12 col-lg-12">
              <div id="accordion" className="accordion faq-wrap">
                <div className="card mb-3">
                  <a className="card-header " data-toggle="collapse" href="#collapse0" aria-expanded="false">
                    <h6 className="mb-0 d-inline-block">What is Shakib 75 health?</h6>
                  </a>
                  <div id="collapse0" className="collapse show" data-parent="#accordion">
                    <div className="card-body white-bg">
                      <p>Shakib 75 health is an online based medical consultation service where doctors
                        consult patients on video calls using Shakib 75 health app or website. The
                        service is available 24/7 from anywhere.</p>
                    </div>
                  </div>
                </div>
                <div className="card my-3">
                  <a className="card-header collapsed" data-toggle="collapse" href="#collapse1" aria-expanded="false">
                    <h6 className="mb-0 d-inline-block">Is it available 24/7?</h6>
                  </a>
                  <div id="collapse1" className="collapse " data-parent="#accordion">
                    <div className="card-body white-bg">
                      <p>Yes, our experienced doctor is there 24/7 to provide you great services.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card my-3">
                  <a className="card-header collapsed" data-toggle="collapse" href="#collapse2" aria-expanded="false">
                    <h6 className="mb-0 d-inline-block">Does this support all devices?</h6>
                  </a>
                  <div id="collapse2" className="collapse " data-parent="#accordion">
                    <div className="card-body white-bg">
                      <p>It will support any android devices.</p>
                    </div>
                  </div>
                </div>

                <div className="card my-3">
                  <a className="card-header collapsed" data-toggle="collapse" href="#collapse3" aria-expanded="false">
                    <h6 className="mb-0 d-inline-block">How to order ?</h6>
                  </a>
                  <div id="collapse3" className="collapse " data-parent="#accordion">
                    <div className="card-body white-bg">
                      <p>People can order the healthcard by simply fill up a google form pay 475 tk via Bkash (09638007575).  Customers will have their healthcard within 4 days.</p>
                    </div>
                  </div>
                </div>


                <div className="card my-3">
                  <a className="card-header collapsed" data-toggle="collapse" href="#collapse4" aria-expanded="false">
                    <h6 className="mb-0 d-inline-block">Payment Process</h6>
                  </a>
                  <div id="collapse4" className="collapse " data-parent="#accordion">
                    <div className="card-body white-bg">
                      <p className='text-center dt' style={{ fontWeight: "700" }}>Pay with</p>
                      <div className="pay-with">
                        <div className="images  row">
                          {payWith.map((item, index) =>
                            <img loading="lazy" key={index} src={item.image} alt={item.name} className="m-2" style={{ maxWidth: "100px" }} />
                          )}
                        </div>
                      </div>
                      {/* <p>Payment must be made through Bkash.</p> */}
                      <p className='text-center mt-3'>*** Pay only 475 taka for the healthcard and get the discount for a whole year *** </p>
                    </div>
                  </div>
                </div>

                <div className="card my-3">
                  <a className="card-header collapsed" data-toggle="collapse" href="#collapse5" aria-expanded="false">
                    <h6 className="mb-0 d-inline-block">Home Delivery</h6>
                  </a>
                  <div id="collapse5" className="collapse " data-parent="#accordion">
                    <div className="card-body white-bg">
                      <p>Enjoy free delivery all over Bangladesh</p>
                    </div>
                  </div>
                </div>
              </div>

              <p className='text-center'>*** We are providing this service only inside Dhaka for now. Consequently, we will offer it to companies outside Dhaka as well.***</p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Faq