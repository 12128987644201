import React from 'react'

const PreFooter = () => {
  return (
    <>
      <div className="footer-bottom py-3 gray-light-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-7">
              <div className="copyright-wrap small-text">
                <p className="mb-0">All rights reserved by <b>Shakib 75</b></p>
              </div>
            </div>
            <div className="col-md-6 col-lg-5">
              {
                /* <div class="terms-policy-wrap text-lg-right text-md-right text-left">
                   <ul class="list-inline">
                       <li class="list-inline-item"><a class="small-text" href="#">Terms</a></li>
                       <li class="list-inline-item"><a class="small-text" href="#">Security</a></li>
                       <li class="list-inline-item"><a class="small-text" href="#">Privacy Policy</a></li>
                   </ul>
                </div> */
              }
              <div className="text-lg-right text-md-right text-left">
                <p className="mb-0">Technology Partner <a href="https://cistechltd.com/" target="_blank" rel="noreferrer"><b>CIS Tech Ltd.</b></a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PreFooter