// import axios from 'axios'
// import React, { useState, useEffect } from 'react'
// import { apiURL } from '../utils/config'

import React from 'react'
const Teams = () => {
    //   const [doctors, setDoctors] = useState([])
    //   const getDoctor = (() => {
    //     axios.get(apiURL + 'webdoctorlist')
    //       .then(function (response) {
    //         // handle success
    //         console.log("response");
    //         console.log(response.data.data);
    //         setDoctors(response.data.data)
    //       })
    //       .catch(function (error) {
    //         // handle error
    //         console.log(error);

    //       })
    //   })
    //   useEffect(() => {
    //     getDoctor()
    //   }, [])

    const teams = [
        {
            id: '5',
            name: 'Abu Salman Mohammad Abdullah',
            position: 'MBA, Institute of Business Administration, Dhaka University',
            image: '/assets/img/team/4.png',
            area: 'Director, Marketing, Shakib75 Healthcare Services Limited'
        },
        {
            id: '1',
            name: 'Dr. Monzur Hussain Chowdhury',
            position: 'Medical Consultant',
            image: '/assets/img/team/1.png',
            area: 'Shakib75 Healthcare'
        },

        {
            id: '4',
            name: 'Md Mahedi Hasan',
            position: 'Director Logistics',
            image: '/assets/img/team/5.png',
            area: 'Shakib75 Healthcare'
        },
        {
            id: '2',
            name: 'Dr. Sumiya Akther',
            position: 'Medical Consultant',
            image: '/assets/img/team/2.png',
            area: 'Shakib75 Healthcare'

        },
        {
            id: '3',
            name: 'Dr. Shanzida Zahan',
            position: 'Medical Services Co-Ordinator',
            image: '/assets/img/team/3.png',
            area: 'Shakib75 Healthcare'

        }


    ]

    return (
        <>
            <section className="team-two-section ptb-100 gray-light-bg" id="teams">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-9 col-lg-8">
                            <div className="section-heading text-center">
                                <h2>Our Teams</h2>
                                <p>Teamwork is the ability to work together toward a common vision. We appreciate each member of our team for going above and beyond to help us achieve our goal.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        {teams.map((team) =>
                            <div className="col-md-6 col-lg-4" key={team.id}>
                                <div className="single-team-wrap bg-white text-center border rounded p-4 mt-4" style={{ height: "95%" }}>
                                    <img loading="lazy" src={team.image ? team.image : "/assets/img/avatar.jpg"} alt={team.name} className="img-fluid m-auto rounded-circle" style={{ height: "120px", width: "120px", border: "3px solid #6c757d85" }} />
                                    <div className="team-content mt-4">
                                        <div>
                                            <h5 className="mb-0">{team.name}</h5>
                                            <span className="text-justify">{team.position}</span>
                                        </div>
                                        <p className="mt-3">{team.area}</p>

                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* <div className="action-btns mt-4 d-flex justify-content-center">
            <a href="#" className="btn btn-brand-02">View All Doctors</a>
          </div> */}
                </div>
            </section>
        </>
    )
}

export default Teams