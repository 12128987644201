import { Route, Routes } from "react-router-dom";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
// import Loader from "./components/Loader";
import NavBar from "./components/NavBar";
import PreFooter from "./components/PreFooter";
import PreLoader from "./components/PreLoader";
import Card from "./containers/Card";
import Home from "./containers/Home";
import PrivacyPolicy from "./containers/PrivacyPolicy";

function App() {
  // const [loader, setLoader] = useState(true)

  // if (loader) {
  //   setTimeout(function () {
  //     setLoader(false)
  //   }, 1000);
  // }
  return (
    // loader ? <Loader /> :
    <>
       <PreLoader />
      <NavBar />
      <div className="main">
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/card" element={<Card />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        </Routes>
        <ContactUs />
      </div>
      <Footer />
      <PreFooter />
      <div className="scroll-top scroll-to-target primary-bg text-white" data-target="html">
        <span className="fas fa-hand-point-up" />
      </div>
    </>
  );
}

export default App;
