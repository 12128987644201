import React from 'react'

const PreLoader = () => {
  return (
    <>
      <div id="preloader" className={"preloader"}>
        <div className="preloader-wrap">
          <img loading="lazy" src="assets/img/loader.gif" alt="logo" className="img-fluid" style={{
            width: '150px'
          }} />
        </div>
      </div>
    </>
  )
}

export default PreLoader