import React from 'react'

const DownloadArea = () => {
  return (
    <>
      <section className="bg-image ptb-100" image-overlay={8}>
        <div className="background-image-wraper" style={{
          background: 'url("assets/img/mobile_ui/mock3.png")no-repeat center center / cover fixed',
          opacity: 1
        }}>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-7 col-lg-6 mb-5 mb-md-5 mb-sm-5 mb-lg-0">
              <div className="download-img">
                <div className="discount-banner-2 bg-white text-center">
                  <img loading="lazy" src="assets/img/video.jpeg" alt="download" className="img-fluid img-rad" />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="section-heading text-left mb-1 text-white">
                <h2 className="text-white">Chat and video call with Specialist Doctors</h2>
                <p>We think that everyone should have easy access to excellent
                  healthcare. Our aim is to make the procedure as simple as
                  possible for our patients and to offer treatment no matter
                  where they are.</p>
                <div className="action-btns">
                  <ul className="list-inline">

                    <li className="list-inline-item my-2">
                      <a href="https://play.google.com/store/apps/details?id=com.creativeitsoft.shakib75" target="_blank" rel="noreferrer" className="d-flex align-items-center app-download-btn btn btn-brand-03 btn-rounded">
                        <span className="fab fa-google-play icon-size-sm mr-3" />
                        <div className="download-text text-left">
                          <small>Download form</small>
                          <h5 className="mb-0">Google Play</h5>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {
              /*end col*/
            }
          </div>
          {
            /*end row*/
          }
        </div>
      </section>
    </>
  )
}

export default DownloadArea