import React from 'react'
import CovidCare from '../components/CovidCare'
import Doctors from '../components/Doctors'
import DownloadArea from '../components/DownloadArea'
import Faq from '../components/Faq'
import Features from '../components/Features'
import Header from '../components/Header'
import HealthCard from '../components/HealthCard'
import Interfaces from '../components/Interfaces'
import Services from '../components/Services'
import Teams from '../components/Teams'
import WorkProcess from '../components/WorkProcess'

const Home = () => {
    return (
        <>

            <Header />
            <HealthCard />
            <CovidCare />
            <DownloadArea />
            <Services />
            <Features />
            <Interfaces />
            {/* <Testimonial /> */}
            <Teams />
            <WorkProcess />
            <Doctors />
            {/* <SpecialityArea/> */}
            <Faq />

        </>
    )
}

export default Home