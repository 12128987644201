import axios from 'axios';
import React from 'react'
import { useForm } from 'react-hook-form';
import { NotificationManager } from 'react-notifications';
import config from '../utils/config';
// import { apiURL } from '../utils/config';

const ContactUs = () => {

  const { register, handleSubmit, formState: { errors } } = useForm();
  // const onSubmit = data => console.log(data);
  // console.log(errors);
  const onSubmit = ((data) => {
    axios.post(config.apiURL + "contactus", data)
      .then(function (response) {
        console.log(response);
        NotificationManager.success('Success', 'Your Submission has been sent');
      })
      .catch(function (error) {
        console.log(error);
        NotificationManager.error('Error', 'Something is wrong!');
      });
  })

  return (
    <>
      <section id="contact-us" className="contact-us-section ptb-100">
        <div className="container">
          <div className="row justify-content-around">
            <div className="col-12 pb-3 message-box d-none">
              <div className="alert alert-danger" />
            </div>
            <div className="col-md-12 col-lg-5 mb-5 mb-md-5 mb-sm-5 mb-lg-0">
              <div className="contact-us-form gray-light-bg rounded p-5">
                <h4>Ready to get started?</h4>
                <form onSubmit={handleSubmit(onSubmit)}>

                  <div className="form-row">
                    <div className="col-12">
                      <div className="form-group">
                        <input type="text" className="form-control" placeholder="Enter name" {...register("name", { required: "Name is Required.", maxLength: 80 })} />

                        <div className={`invalid-feedback ${errors?.name?.message && `d-block`} `}>
                          {errors?.name?.message}
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <input type="email" className="form-control" placeholder="Enter email" {...register("email", { required: "Email is Required.", pattern: /^\S+@\S+$/i })} />

                        <div className={`invalid-feedback ${errors?.email?.message && `d-block`} `}>
                          {errors?.email?.message}
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <input type="text" className="form-control" placeholder="Mobile number" {...register("mobile", { required: "Phone is Required", maxLength: 12 })} />

                        <div className={`invalid-feedback ${errors?.mobile?.message && `d-block`} `}>
                          {errors?.mobile?.message}
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <textarea id="message" className="form-control" rows={7} cols={25} placeholder="Message" {...register("message", { required: "Message is Required.", maxLength: 12 })} />

                        <div className={`invalid-feedback ${errors?.message?.message && `d-block`} `}>
                          {errors?.message?.message}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 mt-3">
                      <button type="submit" className="btn btn-brand-02" id="btnContactUs">
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>

              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="contact-us-content">
                <h2>Get in touch with us</h2>
                <p className="lead">Give us a call or drop by anytime, we endeavour to answer all enquiries
                  within 24 hours on business days.</p>
                <hr className="my-5" />
                <ul className="contact-info-list">
                  <li className="d-flex pb-3">
                    <div className="contact-icon mr-3">
                      <span className="fas fa-location-arrow color-primary rounded-circle p-3" />
                    </div>
                    <div className="contact-text">
                      <h5 className="mb-1">Company Location</h5>
                      <p>
                        House #23,Road No. #06,Block #C,Banani,Dhaka-1213
                      </p>
                    </div>
                  </li>
                  <li className="d-flex pb-3">
                    <div className="contact-icon mr-3">
                      <span className="fas fa-phone-alt color-primary rounded-circle p-3" />
                    </div>
                    <div className="contact-text">
                      <h5 className="mb-1">Hotline Number</h5>
                      <p>
                        <a href="tel:09638007575">09638007575</a>
                      </p>
                    </div>
                  </li>
                  <li className="d-flex pb-3">
                    <div className="contact-icon mr-3">
                      <span className="fas fa-envelope color-primary rounded-circle p-3" />
                    </div>
                    <div className="contact-text">
                      <h5 className="mb-1">Email Address</h5>
                      <p>
                        <a href="mailto:support@shakib75.health">shakib75.healthcare@gmail.com</a>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ContactUs